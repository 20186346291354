<script setup lang="ts">
import type { VoixCheckboxFieldInterface, VoixTextFieldInterface } from '@voix/types'

const props = defineProps<{
  fields: {
    specialId: VoixTextFieldInterface
    openLabel: VoixTextFieldInterface
    openOnLoad: VoixCheckboxFieldInterface
    showBookNow: VoixCheckboxFieldInterface
  }
}>()

defineSlice({
  preview: 'SlicesGlueSpecialsSpecialsSlideOut.jpg',
  description: 'Module that slides out and shows the selected offer or newsletter signup',
  fields: {
    specialId: { type: 'text', label: 'Special' },
    openLabel: { type: 'text', label: 'Open Label', default: '"Travel Info"' },
    openOnLoad: { type: 'checkbox', label: 'Open on load', default: false },
    showBookNow: { type: 'checkbox', label: 'Show Book Now', default: true },
  },
  name: { label: 'Specials Slide Out', group: 'Glue' },
  templates: [{ label: 'Specials Slide Out', fields: { specialId: { value: '465' } } }],
})

const isOpen = ref(false)
const special = ref(null)
const size = ref({
  width: 360,
  height: 560,
})

const desktopContainerStyles = computed(() => {
  const styles: { [key: string]: string } = {
    width: `${size.value.width}px`,
    height: `${size.value.height}px`,
  }
  if (isOpen.value === false)
    styles.transform = `translateX(${-size.value.width + 15}px)`

  return styles
})

const mobileContainerStyles = computed(() => {
  const styles: { [key: string]: string } = {
    height: `${size.value.height}px`,
  }
  if (isOpen.value === false)
    styles.transform = `translateY(${size.value.height - 15}px)`

  return styles
})

function setAutoClose() {
  if (props.fields.openOnLoad.value === true) {
    setTimeout(() => {
      isOpen.value = false
    }, 7000)
  }
}

const { data } = await $voixNuxtApi(`/api/devise/specials/${props.fields.specialId.value}`)
special.value = data.value?.data

onMounted(() => {
  if (
    props.fields.openOnLoad.value === true
    && process.client
    && window.innerWidth > 768
  ) {
    isOpen.value = true
  }

  setAutoClose()
})
</script>

<template>
  <div>
    <div v-if="special" class="tracking-sidebar-offer">
      <div
        class="hidden md:block fixed z-[9999] left-0 top-0 mt-32 transform duration-1000 bg-white"
        :style="desktopContainerStyles"
      >
        <GlueSpecialsSpecialsLayoutsSmallSpecial
          :special="special"
          :show-book-now="fields.showBookNow.value"
          location="slideOut"
        />

        <div
          class="cursor-pointer bg-white absolute right-0 top-0 px-6 font-sans uppercase text-glueblue-600 text-xs transform origin-bottom-right -rotate-90 flex items-center justify-between"
          style="width: 196px; height: 40px; margin-right: -39px"
          @click="isOpen = !isOpen"
        >
          <div>
            <svg
              class="w-4 h-4 transform duration-1000"
              :class="{ 'rotate-180': !isOpen }"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 15l7-7 7 7"
              />
            </svg>
          </div>
          <div>{{ isOpen ? 'Collapse' : fields.openLabel.value }}</div>
          <div>
            <svg
              class="w-4 h-4 transform duration-1000"
              :class="{ 'rotate-180': !isOpen }"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 15l7-7 7 7"
              />
            </svg>
          </div>
        </div>
      </div>
      <div
        class="md:hidden fixed z-[60] left-0 right-0 bottom-0 transform duration-1000 bg-white flex"
        :style="mobileContainerStyles"
      >
        <GlueSpecialsSpecialsLayoutsSmallSpecial
          class="w-full"
          :special="special"
          :image-on-top="true"
        />

        <div
          class="cursor-pointer w-full bg-white absolute right-0 top-0 px-6 font-sans uppercase text-glueblue-600 text-xs origin-bottom-right flex items-center justify-between"
          style="height: 48px"
          :class="{ '-mt-8 animate-bounce-slow': !isOpen, '-mt-12': isOpen }"
          @click="isOpen = !isOpen"
        >
          <div class="ml-16">
            <svg
              class="w-4 h-4 transform duration-1000"
              :class="{ 'rotate-180': isOpen }"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 15l7-7 7 7"
              />
            </svg>
          </div>
          <div>{{ isOpen ? 'Collapse' : fields.openLabel.value }}</div>
          <div class="mr-16">
            <svg
              class="w-4 h-4 transform duration-1000"
              :class="{ 'rotate-180': isOpen }"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 15l7-7 7 7"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
